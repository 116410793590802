<template>
  <div
    v-if="validAsset"
    class="w-full h-full bg-scene-gray"
  >
    <PrevizViewerContainer
      :id="asset.id"
      :container-id="containerId"
      mode="scene"
      :supress-controls="!playMode"
    />
  </div>
</template>
<script>
import { PrevizSequenceViewer } from '@previz/viewer'
import PrevizViewerContainer from './container/PrevizViewer'

let previzviewer = null

export default {
  name: 'SceneContainer',

  components: {
    PrevizViewerContainer
  },

  props: {
    sequence: {
      type: Object,
      required: true
    },
    resources: {
      type: Array,
      required: true
    },

    asset: {
      type: Object,
      required: true
    },
    playMode: {
      type: Boolean,
      default: true
    }
  },

  computed: {

    containerId () {
      return 'viewerContainer-' + this.asset.id
    },

    validAsset () {
      return this.asset !== null
    },

    url () {
      if (this.asset) return this.asset.url
    },

    settings () {
      if (this.asset) return this.asset.settings
    }

  },

  mounted () {
    this.initViewer()
  },

  methods: {
    initViewer () {
      this.$nextTick(() => {
        previzviewer = new PrevizSequenceViewer(this.sequence, this.resources, this.containerId)

        var event = new CustomEvent('previz-viewer-attached', { detail: previzviewer })
        window.dispatchEvent(event)
      })
    }
  }
}
</script>
